import { Resource as ResourceType } from '../types';

export const contractMilestone: ResourceType = {
  name: 'contract_milestones',
  label: 'resource.contract_milestones.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.contract_milestones.field.id.label',
      type: 'text',
    },
    {
      key: 'mission_id',
      label: 'resource.contract_milestones.field.mission_id.label',
      type: 'reference',
      reference: 'missions',
    },
    {
      key: 'title',
      label: 'resource.contract_milestones.field.title.label',
      type: 'translatedText',
    },
    {
      key: 'begin_date',
      label: 'resource.contract_milestones.field.begin_date.label',
      type: 'date',
    },
    {
      key: 'end_date',
      label: 'resource.contract_milestones.field.end_date.label',
      type: 'date',
    },
    {
      key: 'for_expert',
      label: 'resource.contract_milestones.field.for_expert.label',
      type: 'boolean',
    },
    {
      key: 'for_client',
      label: 'resource.contract_milestones.field.for_client.label',
      type: 'boolean',
    },
    {
      key: 'units_worked',
      label: 'resource.contract_milestones.field.expert_days_worked.label',
      type: 'number',
    },
    {
      key: 'admin_units_worked',
      label: 'resource.contract_milestones.field.admin_days_worked.label',
      type: 'number',
    },
    {
      key: 'price',
      label: 'resource.contract_milestones.field.price.label',
      type: 'number',
    },
    {
      key: 'admin_price',
      label: 'resource.contract_milestones.field.admin_price.label',
      type: 'number',
    },
    {
      key: 'status',
      label: 'resource.contract_milestones.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'creation_review_admin',
          name: 'resource.contract_milestones.field.status.choices.creation_review_admin.label',
        },
        {
          id: 'creation_review_client',
          name: 'resource.contract_milestones.field.status.choices.creation_review_client.label',
        },
        {
          id: 'created',
          name: 'resource.contract_milestones.field.status.choices.created.label',
        },
        {
          id: 'started',
          name: 'resource.contract_milestones.field.status.choices.started.label',
        },
        {
          id: 'delayed',
          name: 'resource.contract_milestones.field.status.choices.delayed.label',
        },
        {
          id: 'modification_review_admin',
          name: 'resource.contract_milestones.field.status.choices.modification_review_admin.label',
        },
        {
          id: 'modification_review_client',
          name: 'resource.contract_milestones.field.status.choices.modification_review_client.label',
        },
        {
          id: 'cancelation_review_admin',
          name: 'resource.contract_milestones.field.status.choices.cancelation_review_admin.label',
        },
        {
          id: 'cancelation_review_client',
          name: 'resource.contract_milestones.field.status.choices.cancelation_review_client.label',
        },
        {
          id: 'canceled',
          name: 'resource.contract_milestones.field.status.choices.canceled.label',
        },
        {
          id: 'delivery_review_admin',
          name: 'resource.contract_milestones.field.status.choices.delivery_review_admin.label',
        },
        {
          id: 'delivery_review_client',
          name: 'resource.contract_milestones.field.status.choices.delivery_review_client.label',
        },
        {
          id: 'delivery_with_update_review_admin',
          name: 'resource.contract_milestones.field.status.choices.delivery_with_update_review_admin.label',
        },
        {
          id: 'delivery_with_update_review_client',
          name: 'resource.contract_milestones.field.status.choices.delivery_with_update_review_client.label',
        },
        {
          id: 'rejected',
          name: 'resource.contract_milestones.field.status.choices.rejected.label',
        },
        {
          id: 'delivered',
          name: 'resource.contract_milestones.field.status.choices.delivered.label',
        },
      ],
    },
    {
      key: 'contract_milestone_id',
      label: 'resource.contract_milestones.field.contract_milestone_id.label',
      type: 'reference',
      reference: 'contract_milestones',
    },
    {
      key: 'created_at',
      label: 'resource.contract_milestones.field.created_at.label',
      type: 'date',
    },
    {
      key: 'description',
      label: 'resource.contract_milestones.field.description.label',
      type: 'text',
      multiline: true,
    },
    {
      key: 'updated_at',
      label: 'resource.contract_milestones.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'contract_id',
      label: 'resource.contract_milestones.field.contract_id.label',
      type: 'reference',
      reference: 'contracts',
    },
    {
      key: 'expert_invoice_id',
      label: 'resource.contract_milestones.field.expert_invoice_id.label',
      type: 'reference',
      reference: 'invoices',
    },
    {
      key: 'client_invoice_id',
      label: 'resource.contract_milestones.field.client_invoice_id.label',
      type: 'reference',
      reference: 'invoices',
    },
    {
      key: 'client_owner_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'owner_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'expert_delivery_attachments',
      label:
        'resource.contract_milestones.field.expert_delivery_attachments.label',
      type: 'buttons',
    },
    {
      key: 'expert_delivery_comment',
      label: 'resource.contract_milestones.field.expert_delivery_comment.label',
      type: 'text',
    },
    {
      key: 'client_delivery_attachments',
      label:
        'resource.contract_milestones.field.client_delivery_attachments.label',
      type: 'buttons',
    },
    {
      key: 'client_delivery_comment',
      label: 'resource.contract_milestones.field.client_delivery_comment.label',
      type: 'text',
    },
    {
      key: 'purchase_order',
      label: 'resource.contract_milestones.field.purchase_order.label',
      type: 'text',
      multiline: true,
    },
    {
      key: 'accepted_at',
      label: 'resource.contract_milestones.field.accepted_at.label',
      type: 'date',
    },
    {
      key: 'discount',
      label: 'resource.proposals.field.discount.label',
      type: 'number',
    },
    {
      key: 'payment_period',
      label: 'resource.contract_milestones.field.payment_period.label',
      type: 'select',
      choices: [
        {
          id: 'thirty_days',
          name: 'resource.contract_milestones.field.payment_period.choices.thirty_days_x.label',
        },
        {
          id: 'fifteen_days',
          name: 'resource.contract_milestones.field.payment_period.choices.fifteen_days_x.label',
        },
        {
          id: 'seven_days',
          name: 'resource.contract_milestones.field.payment_period.choices.seven_days.label',
        },
        {
          id: 'immediate',
          name: 'resource.contract_milestones.field.payment_period.choices.immediate.label',
        },
      ],
    },
    {
      key: 'quotation_kind',
      type: 'text',
    },
    {
      key: 'vat',
      label: 'resource.contract_milestones.field.vat.label',
      type: 'number',
    },
    {
      key: 'minutes',
      label: 'resource.contract_milestones.field.minutes.label',
      type: 'file',
    },
    {
      key: 'old_forfeit',
      type: 'boolean',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      hasCreateButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
      ],
      fields: [
        {
          source: 'id',
          sortable: false,
        },
        {
          source: 'mission_id',
          child: {
            source: 'missions.name',
          },
        },
        {
          source: 'title',
        },
        {
          source: 'begin_date',
        },
        {
          source: 'end_date',
        },
        {
          source: 'units_worked',
        },
        {
          source: 'price',
        },
        {
          source: 'admin_price',
        },
        {
          source: 'status',
        },
        {
          source: 'contract_milestone_id',
          child: {
            source: 'contract_milestones.title',
          },
        },
        {
          source: 'created_at',
        },
      ],
    },
    create: {
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.contract_milestones.tab.information.title',
            sections: [
              {
                key: 'information',
                title: 'resource.contract_milestones.section.information.title',
                layout: [
                  ['contract_id'],
                  ['title'],
                  ['description'],
                  ['begin_date', 'end_date'],
                  ['for_expert', 'for_client'],
                  ['units_worked', 'admin_units_worked'],
                  ['vat'],
                  ['price', 'admin_price'],
                  ['status'],
                  ['purchase_order'],
                  ['accepted_at'],
                  ['discount', 'payment_period'],
                ],
                inputs: {
                  contract_id: {
                    source: 'contract_id',
                    type: 'reference',
                    reference: 'contracts',
                    child: {
                      type: 'autocomplete',
                      optionText: 'name',
                      extensions: [
                        {
                          key: 'concatenateParams',
                          params: {
                            first: 'mission_name',
                            second: 'mission_company_name',
                          },
                        },
                      ],
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  title: {
                    validators: [
                      {
                        key: 'maxLength',
                        value: 50,
                      },
                      {
                        key: 'required',
                      },
                    ],
                  },
                  description: {},
                  begin_date: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  end_date: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  for_expert: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    initialValue: true,
                  },
                  for_client: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    initialValue: true,
                  },
                  units_worked: {
                    width: 6,
                    validators: [
                      {
                        key: 'minValue',
                        value: 0.01,
                      },
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      hide: [
                        {
                          hideError: true,
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  admin_units_worked: {
                    width: 6,
                    validators: [
                      {
                        key: 'minValue',
                        value: 0.01,
                      },
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      hide: [
                        {
                          hideError: true,
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  vat: {
                    width: 12,
                    validators: [
                      {
                        key: 'minValue',
                        value: 0,
                      },
                      {
                        key: 'maxValue',
                        value: 100,
                      },
                    ],
                  },
                  price: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                        rules: [
                          {
                            hideError: true,
                            property: 'quotation_kind',
                            op: 'eq',
                            value: 'forfeit',
                          },
                        ],
                      },
                    ],
                    rules: {
                      disabled: [
                        {
                          hideError: true,
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  admin_price: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                        rules: [
                          {
                            hideError: true,
                            property: 'quotation_kind',
                            op: 'eq',
                            value: 'forfeit',
                          },
                        ],
                      },
                    ],
                    rules: {
                      disabled: [
                        {
                          hideError: true,
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  discount: {
                    width: 6,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      {
                        key: 'required',
                      },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                      {
                        key: 'maxValue',
                        value: 100,
                      },
                    ],
                    min: 0,
                    max: 100,
                    step: 0.01,
                  },
                  payment_period: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                },
              },
              {
                key: 'charges',
                title: 'resource.milestones.tab.charges.title',
                layout: [],
                inputs: {},
                rules: {
                  hide: [
                    {
                      hideError: true,
                      property: 'quotation_kind',
                      op: 'eq',
                      value: 'forfeit',
                    },
                  ],
                },
                relatedRecord: {
                  recordToCreate: 'charges',
                  addRecordLabel: 'action.add_related_charges',
                  modalTitle: 'modal.contract_milestone.add_charges',
                  inputs: {
                    charge_title: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    unit: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    admin_quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                    admin_cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                  },
                },
              },
              {
                key: 'forfeit_charges',
                title: 'resource.milestones.tab.forfeit_charges.title',
                layout: [],
                inputs: {},
                rules: {
                  hide: [
                    {
                      hideError: true,
                      property: 'quotation_kind',
                      op: 'eq',
                      value: 'technical_assistance',
                    },
                  ],
                },
                relatedRecord: {
                  recordToCreate: 'charges',
                  addRecordLabel: 'action.add_related_forfeit_charges',
                  modalTitle: 'modal.contract_milestone.add_forfeit_charges',
                  inputs: {
                    charge_title: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    unit: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    admin_quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                    admin_cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    },
    edit: {
      singleRedirect: {
        page: 'edit',
        basePath: '/contract_milestones',
        recordId: 'id',
      },
      actions: [
        {
          name: 'validate',
          type: 'update',
          label:
            'resource.contract_milestones.bulk_action_buttons.validate.label',
          values: { validated: true },
          extraUrl: '/',
          disabled: [
            {
              property: 'status',
              op: 'not_includes',
              value: [
                'creation_review_admin',
                'modification_review_admin',
                'cancelation_review_admin',
                'delivery_review_admin',
                'delivery_with_update_review_admin',
              ],
            },
          ],
        },
        {
          name: 'unvalidate',
          type: 'update',
          label:
            'resource.contract_milestones.bulk_action_buttons.unvalidate.label',
          values: { validated: false },
          extraUrl: '/',
          disabled: [
            {
              property: 'status',
              op: 'not_includes',
              value: [
                'creation_review_admin',
                'modification_review_admin',
                'cancelation_review_admin',
                'delivery_review_admin',
                'delivery_with_update_review_admin',
              ],
            },
          ],
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.contract_milestones.tab.information.title',
            sections: [
              {
                key: 'information',
                title: 'resource.contract_milestones.section.information.title',
                layout: [
                  ['title'],
                  ['description'],
                  ['begin_date', 'end_date'],
                  ['for_expert', 'for_client'],
                  ['vat'],
                  ['units_worked', 'admin_units_worked'],
                  ['price', 'admin_price'],
                  ['forfeit_old_price', 'forfeit_old_admin_price'],
                  ['forfeit_new_price', 'forfeit_new_admin_price'],
                  ['status'],
                  ['purchase_order'],
                  ['accepted_at'],
                  ['discount', 'payment_period'],
                ],
                inputs: {
                  title: {
                    validators: [
                      {
                        key: 'maxLength',
                        value: 50,
                      },
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      disabled: [
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  description: {},
                  begin_date: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  end_date: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  for_expert: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    rules: {
                      disabled: [
                        {
                          property: 'status',
                          op: 'includes',
                          value: ['rejected', 'delivered', 'canceled'],
                        },
                      ],
                    },
                  },
                  for_client: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    rules: {
                      disabled: [
                        {
                          property: 'status',
                          op: 'includes',
                          value: ['rejected', 'delivered', 'canceled'],
                        },
                      ],
                    },
                  },
                  vat: {
                    width: 12,
                    validators: [
                      {
                        key: 'minValue',
                        value: 0,
                      },
                      {
                        key: 'maxValue',
                        value: 100,
                      },
                    ],
                  },
                  units_worked: {
                    width: 6,
                    validators: [
                      {
                        key: 'minValue',
                        value: 0.01,
                      },
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  admin_units_worked: {
                    width: 6,
                    validators: [
                      {
                        key: 'minValue',
                        value: 0.01,
                      },
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  price: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  admin_price: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  // TODO: Delete this logic when old forfeit contract will be over
                  // delete forfeit_old_price and forfeit_old_admin_price
                  // rename forfeit_new_price and forfeit_new_admin_price in forfeit_price and forfeit_admin_price
                  forfeit_old_price: {
                    source: 'price',
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'old_forfeit',
                          op: 'eq',
                          value: false,
                        },
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  forfeit_old_admin_price: {
                    source: 'admin_price',
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'old_forfeit',
                          op: 'eq',
                          value: false,
                        },
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  forfeit_new_price: {
                    source: 'price',
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'old_forfeit',
                          op: 'eq',
                          value: true,
                        },
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  forfeit_new_admin_price: {
                    source: 'admin_price',
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'old_forfeit',
                          op: 'eq',
                          value: true,
                        },
                        {
                          property: 'quotation_kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  status: {
                    disabled: true,
                  },
                  purchase_order: {},
                  accepted_at: {},
                  discount: {
                    width: 6,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      {
                        key: 'required',
                      },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                      {
                        key: 'maxValue',
                        value: 100,
                      },
                    ],
                    min: 0,
                    max: 100,
                    step: 0.01,
                  },
                  payment_period: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                },
              },
              {
                key: 'charges',
                title:
                  'resource.quotations.section.information.extra_cost.title',
                layout: [['charges']],
                inputs: {},
                rules: {
                  hide: [
                    {
                      hideError: true,
                      property: 'quotation_kind',
                      op: 'eq',
                      value: 'forfeit',
                    },
                  ],
                },
                relatedRecord: {
                  recordToCreate: 'charges',
                  addRecordLabel: 'action.add_related_charges',
                  filter_resource: 'contract_milestone',
                  filter_attribute: 'id',
                  modalTitle: 'modal.contract_milestone.add_charges',
                  inputs: {
                    charge_id: {
                      disabled: true,
                      width: 12,
                      hide: true,
                    },
                    charge_title: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    unit: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    admin_quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                    admin_cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                  },
                },
              },
              {
                key: 'forfeit_charges',
                title:
                  'resource.quotations.section.information.forfeit_extra_cost.title',
                layout: [['charges']],
                inputs: {},
                rules: {
                  hide: [
                    {
                      hideError: true,
                      property: 'quotation_kind',
                      op: 'eq',
                      value: 'technical_assistance',
                    },
                    {
                      property: 'old_forfeit',
                      op: 'eq',
                      value: true,
                    },
                  ],
                },
                relatedRecord: {
                  recordToCreate: 'charges',
                  addRecordLabel: 'action.add_related_forfeit_charges',
                  filter_resource: 'contract_milestone',
                  filter_attribute: 'id',
                  modalTitle: 'modal.contract_milestone.add_forfeit_charges',
                  inputs: {
                    charge_id: {
                      disabled: true,
                      width: 12,
                      hide: true,
                    },
                    charge_title: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    unit: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    admin_quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                    admin_cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                  },
                },
              },
              {
                key: 'delivery',
                title: 'resource.contract_milestones.section.delivery.title',
                layout: [
                  ['expert_delivery_comment'],
                  ['expert_delivery_attachments'],
                  ['client_delivery_comment'],
                  ['client_delivery_attachments'],
                ],
                inputs: {},
                fields: {
                  expert_delivery_comment: {
                    addLabel: true,
                  },
                  expert_delivery_attachments: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                  },
                  client_delivery_comment: {
                    addLabel: true,
                  },
                  client_delivery_attachments: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                  },
                },
              },
              {
                key: 'minute',
                title: 'resource.contract_milestones.section.minute.title',
                layout: [['minute_file'], ['minutes']],
                inputs: {
                  minutes: {
                    options: {
                      accept: '.pdf',
                      kind: 'minute',
                    },
                  },
                },
                fields: {
                  minute_file: {
                    source: 'id',
                    link: false,
                    label:
                      'resource.contract_milestones.field.minute_file.label',
                    key: 'download',
                    type: 'buttons',
                    extensions: [
                      {
                        key: 'minuteFile',
                        params: {
                          resourceToFetch: 'contract_milestones',
                        },
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.contract_milestones.section.details.title',
              layout: [
                ['id'],
                ['created_at', 'updated_at'],
                ['contract_milestone_id'],
                ['contract_id'],
                ['mission_id'],
                ['expert_invoice_id'],
                ['client_invoice_id'],
                ['owner_id'],
                ['owner_company'],
                ['client_owner_id'],
                ['client_owner_company'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
              },
              fields: {
                contract_milestone_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'contract_milestones.title',
                  },
                },
                contract_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'contract.id',
                  },
                },
                mission_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'mission.name',
                  },
                },
                expert_invoice_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'invoice.guid',
                  },
                },
                client_invoice_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'invoice.guid',
                  },
                },
                owner_id: {
                  addLabel: true,
                  label: 'resource.proposals.field.candidacy_owner_name.label',
                  child: {
                    type: 'text',
                    source: 'user.full_name',
                  },
                },
                owner_company: {
                  source: 'owner_id',
                  addLabel: true,
                  label: 'resource.users.field.company_name_profile.label',
                  link: false,
                  child: {
                    type: 'text',
                    source: 'user.company_name',
                  },
                },
                client_owner_id: {
                  addLabel: true,
                  label: 'resource.candidacies.field.company_name.label',
                  child: {
                    type: 'text',
                    source: 'user.full_name',
                  },
                },
                client_owner_company: {
                  source: 'client_owner_id',
                  addLabel: true,
                  label: 'resource.missions.field.company_id.label',
                  link: false,
                  child: {
                    type: 'text',
                    source: 'user.company_name',
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
